.area6
{
   background-image: url("../images/bg7.jpg");
   background-repeat: no-repeat;
   background-size: cover; 
   background-color: #161616;
   display: flex;
   justify-content: top;
   align-items: center;
   flex-direction: column;
   padding-bottom: 70px;
}

.TrainingItem {
   width: 600px;
   padding-bottom: 10px;
   margin-bottom: 10px;
   text-align: left;
   display: flex;
   border-bottom: 1px solid lightcyan;
   position: relative;
}

.TrainingArea > .TrainingItem:last-child
{
   border-bottom: 0px;
}

.TrainingItem > .Icon {
   width: 40px;
}

.TrainingItem > .Year {
   position: absolute;
   right: 10px;
   font-size: 12px;
   font-weight: bold;
   top: 5px;
   font-style: italic;
}