.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  min-width: 1000px;
}


.mailAndPhoneArea
{
  width: 700px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.header
{
  width: 1000px;
  border: 0px solid red;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cap
{
  text-transform:uppercase ;
}

.textArea
{
   text-align: left;
   border: 0px solid blue;
   height: 100%;
   padding-top: 120px;
   padding-left: 20px;

}

.mainTextColor
{
  color: #00CCFF;
}

.fat
{
  font-weight: bold;
}

.smallHeroSize
{
  font-size: 20px;
  padding-bottom: 10px;
}

.heroArea
{
  border: 0px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
}

.sig
{
  border: 0px solid red;
  text-align: right;
  padding-bottom: 25px;
}

.heroAreaLeft
{
  border: 0px solid red;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.heroSize
{
  font-size: 40px;
}

.mail
{
  margin-top: 25px;
}

.phone
{
  margin-top: 25px;
  margin-right: 20px;
}

.withIcon 
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.withIcon > div
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.withIcon > div:first-child
{
  margin-right: 5px;
  padding-top: 3px;
}

a, a:visited
{
  color: White;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
  cursor: pointer;
}

.area1
{
   height: 654px;
   background-image: url("../images/hero-img.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   justify-content: top;
   align-items: center;
   flex-direction: column;
   position: relative;
}

.area2Start
{
  padding-top: 50px;
  padding-bottom: 12px;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
}

.area2Sub
{
  color: #00CCFF;
  padding-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
}

.area2
{
  padding-bottom: 70px;
}

.donutArea
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.area3space
{
 padding-bottom: 0px;
}

.area3
{
   height: 2150px;
   background-image: url("../images/bg.jpg");
   background-size: 100% auto;
   display: flex;
   justify-content: top;
   align-items: center;
   flex-direction: column;
   padding-bottom: 50px;
}

.area5
{
   /* height: 654px; */
   /* background-image: url("./bg2.jpg");
   background-repeat: no-repeat;
   background-size: cover; */
   background-color: #161616;
   display: flex;
   justify-content: top;
   align-items: center;
   flex-direction: column;
}

.aboutMeArea
{
  text-align: left;
  display: flex;
  align-items: top;
  justify-content: center;
  padding-bottom: 70px;
}

.aboutMeArea
{
  padding-top: 30px;
}

.aboutMeArea  table
{
  padding-left:30px;
}

.aboutMeArea  table td
{
  
    padding-top: 5px;
    padding-bottom: 5px;
}

.aboutMeArea  table tr > td:first-child
{
    font-weight: bold;
    font-size: 14px;
    width: 140px;
}

.aboutMeArea  table tr > td:not(:first-child)
{
    color: lightgray;
}

.aboutMeText
{
  vertical-align: top;
  text-align: left;
  font-size: 14px;
  height: 60px;
  color: lightgray;
}

.hobbyArea
{
  display: flex;
  align-items: center;
  padding-top: 20px;
  width: 250px;
}

.hobbyArea > div:first-child
{
  padding-right: 10px;
}

.flag
{
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid white;
  height: 18px;
}

.flagArea
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-right: 30px;
}