
   .linkareaSmall
   {
     display: none;
     position:absolute;
     right: -20px;
     top: 470px;
   }

   .linkareaNo
   {
     display: none;
     position:absolute;
     left: 50px;
     top: 597px;
   }
   
   .linkarea
   {
     display: none;
     position:absolute;
     right: 0px;
     top: 400px;
   }

@media (min-width:1500px) { 
    .linkarea
    {
      display: inline;
    }
  
   } 
  
   @media (min-width:1120px) and (max-width:1500px) { 
    .linkareaSmall
    {
      display: inline;
    }
  
   } 

   
   @media (min-width:000px) and (max-width:1120px) { 
    .linkareaNo
    {
      display: flex;
    }
  
   } 


   .linkareaNo > a > div
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 13px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 20px;
    padding-left: 8px;
    padding-right: 0px;
    font-weight: bold;
    margin-right: 10px;
  }

    
  .linkareaNo > a > div > div:first-child
  {
     background-color: white;
     border-radius: 30px;
     width: 30px;
     height: 30px;
     margin-right: 10px;
     display:flex;
     justify-content: center;
     align-items: center;
  }
  
  .linkarea > a > div,
  .linkareaSmall > a > div
  {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 20px;
    font-weight: bold;
  }
  
  .linkarea > a:hover,
  .linkareaSmall > a:hover
  {
     text-decoration: none;
  }
  
  .linkarea > a > div > div:first-child,
  .linkareaSmall > a > div > div:first-child
  {
     background-color: white;
     border-radius: 30px;
     width: 30px;
     height: 30px;
     margin-right: 10px;
     display:flex;
     justify-content: center;
     align-items: center;
  }
  
  .soAreaNo
  {
    background-color: mediumaquamarine;
    border-radius: 40px 40px 0px 0px;
  }

  .githubAreaNo
  {
    background-color: deepskyblue;
    border-radius: 40px 40px 0px 0px;
  }
  
  .xingAreaNo
  {
     background-color: darkorange;
     border-radius: 40px 40px 0px 0px;
  }

  .soArea,
  .soAreaSmall
  {
    background-color: mediumaquamarine;
    border-radius: 40px 0px 0px 40px;
  }
  
  .githubArea,
  .githubAreaSmall
  {
    background-color: deepskyblue;
    border-radius: 40px 0px 0px 40px;
  }
  
  .xingArea,
  .xingAreaSmall
  {
     background-color: darkorange;
     border-radius: 40px 0px 0px 40px;
  }

  .blogArea,
  .blogAreaSmall
  {
     background-color: mediumslateblue;
     border-radius: 40px 0px 0px 40px;
  }